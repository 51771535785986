<template>
  <div>
    <b-form @submit.prevent="formSubmitted">
      <b-row class="main_details">
        <b-col cols="12" class="py-1 d-flex justify-content-end">
          <div class="print-btn-wrapper">
            <b-button @click="printContent">Print</b-button>
          </div>
        </b-col>
        <b-col md="6">
          <b-form-group label="Product Name" label-for="mc-Product-Supplier">
            <b-form-input
              id="mc-Product-Supplier"
              type="text"
              v-model="product.name"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Product Code" label-for="mc-Product-Code">
            <b-form-input
              id="mc-Product-Code"
              type="text"
              v-model="product.code"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label-for="mc-stock-threshold" label="Low Stock Threshold">
            <div class="form-label-group">
              <b-form-input
                id="mc-stock-threshold"
                type="text"
                v-model="product.lowStock"
                readonly
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Total Balance" label-for="mc-Balance">
            <b-form-input id="mc-Balance" type="text" v-model="totalBalance" readonly />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-table small :fields="balanceFields" :items="filteredBalances" responsive="sm">
      <template #cell(warehouseId)="data">
        {{ getWarehouseNameById(data.value) }}
      </template>
    </b-table>
    <div class="content-transactions">
      <div class="d-flex justify-content-center mb-1">
        <b-spinner
          variant="success"
          label="Spinning"
          block
          v-if="
            productLoading || balanceLoading || transactionLoading || warehouseLoading
          "
          style="width: 3rem; height: 3rem"
        ></b-spinner>
      </div>
      <div>
        <div class="d-flex justify-content-end my-2">
          <b-button @click="exportToCSV">Export to CSV</b-button>
        </div>
        <b-table
          small
          :fields="transactionFields"
          :items="filteredTransactions"
          responsive="sm"
        >
          <template #cell(type)="data">
            {{ getTypeNameById(data.value) }}
          </template>
          <template #cell(date)="data">
            {{ dayjs(data.value).format("DD/MM/YYYY") }}
          </template>
          <template #cell(fromWarehouse)="data">
            {{ getWarehouseNameById(data.value) }}
          </template>
          <template #cell(toWarehouse)="data">
            {{ getWarehouseNameById(data.value) }}
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BFormTextarea,
  BFormSelect,
  BFormFile,
  BTable,
  BProgress,
  BBadge,
  BContainer,
  BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  components: {
    BRow,
    BSpinner,
    BFormSelect,
    BProgress,
    BBadge,
    BContainer,
    BTable,
    BCol,
    BFormFile,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormTextarea,
    BButton,
  },
  directives: {
    Ripple,
  },
  methods: {},

  data() {
    return {
      dayjs,
      id: this.$route.params.id,
      balanceFields: [
        { key: "warehouseId", label: "Warehouse" },
        { key: "physicalInventory", label: "Balance", sortable: true },
      ],
      transactionFields: [
        { key: "type", label: "Transaction Type" },
        { key: "date", label: "Transaction Date", sortable: true },
        { key: "fromWarehouse", label: "From Warehouse" },
        { key: "toWarehouse", label: "To Warehouse" },
        { key: "qty", label: "Quantity" },
      ],
      types: [
        { value: 1, text: "PURCHASE" },
        { value: 2, text: "PRODUCTION" },
        { value: 3, text: "SALES" },
        { value: 4, text: "RETURN" },
        { value: 5, text: "DEFECT" },
        { value: 6, text: "LOST" },
        { value: 7, text: "STOLEN" },
      ],
      totalBalance: null,
      filteredBalances: [],
      filteredTransactions: [],
    };
  },
  methods: {
    ...mapActions("productModule", ["getProductIDAction"]),
    ...mapActions("itemBalanceModule", ["getItemBalanceListAction"]),
    ...mapActions("inventoryTransactionModule", ["getTransactionListAction"]),
    ...mapActions("warehouseModule", ["getWarehouseListAction"]),

    printContent() {
      window.print();
    },
    sanitizeFilename(filename) {
      return filename.replace(/[\/:*?"<>|]/g, "").replace(/ /g, "_");
    },
    exportToCSV() {
      const rows = this.filteredTransactions.map((transaction) => {
        return {
          type: this.getTypeNameById(transaction.type) || "-",
          date: dayjs(transaction.date).format("DD/MM/YYYY") || "-",
          fromWarehouse: this.getWarehouseNameById(transaction.fromWarehouse) || "-",
          toWarehouse: this.getWarehouseNameById(transaction.toWarehouse) || "-",
          qty: transaction.qty || "-",
        };
      });

      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += Object.keys(rows[0]).join(",") + "\n"; // header

      rows.forEach((row) => {
        const values = Object.values(row);
        csvContent += values.join(",") + "\n";
      });

      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `${this.sanitizeFilename(this.product.name)}.csv`);
      document.body.appendChild(link);
      link.click();
    },
    getTypeNameById(id) {
      let type = this.types.filter((x) => {
        return x.value === id;
      });
      if (type.length > 0) return type[0].text;
      else return id;
    },
    getWarehouseNameById(id) {
      let warehouse = this.warehouses.filter((x) => {
        return x.id === id;
      });
      if (warehouse.length > 0) return warehouse[0].name;
      else return id;
    },
  },
  computed: {
    ...mapGetters("productModule", {
      product: "productID",
      productLoading: "loading",
    }),
    ...mapGetters("itemBalanceModule", {
      itemBalances: "itemBalances",
      balanceLoading: "loading",
    }),
    ...mapGetters("inventoryTransactionModule", {
      transactions: "transactions",
      transactionLoading: "loading",
    }),
    ...mapGetters("warehouseModule", {
      warehouses: "warehouse",
      warehouseLoading: "loading",
    }),
  },
  async mounted() {
    await this.getItemBalanceListAction().then(() => {
      this.filteredBalances = this.itemBalances.filter((obj) => {
        return obj.itemId === this.id;
      });
      this.totalBalance = this.filteredBalances.reduce(function (a, b) {
        return a + b["physicalInventory"] ?? 0;
      }, 0);
    });
    await this.getTransactionListAction().then(() => {
      this.transactions.forEach((trx) => {
        if (trx.inventoryTransactionLine) {
          trx.inventoryTransactionLine.forEach((trxLine) => {
            if (trxLine.itemId === this.id) {
              this.filteredTransactions.push({
                type: trx.type,
                date: trx.date,
                fromWarehouse: trx.fromWarehouse,
                toWarehouse: trx.toWarehouse,
                qty: trxLine.qty,
              });
            }
          });
        }
      });
    });
    await this.getWarehouseListAction();
    await this.getProductIDAction(this.id);
  },
};
</script>

<style lang="scss">
@media print {
  a[href]:after {
    content: none !important;
  }

  html,
  body {
    height: 99% !important;
  }

  * {
    color: black !important;
  }

  body {
    background-color: transparent !important;
    font-size: 17.5px !important;
    color: black !important;
  }

  nav.header-navbar {
    display: none;
  }

  .flatpickr-input {
    font-size: 17.5px !important;
    color: black !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  .content-header {
    display: none !important;
  }

  .content-transactions {
    display: none !important;
  }

  .form-control {
    border: none !important;
    font-weight: bold !important;
    color: black !important;
  }

  .print-btn-wrapper {
    display: none !important;
  }
  .main_details {
    display: flex !important;
    justify-content: center !important;
  }
}
</style>
